import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main'

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/authority",
    name: "authority",
    component: () => import("@/views/Authority"),
  },
  {
    path: "/",
    name: "Main",
    component: Main,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/Dashboard"),
        name: "首页看板",
      },
    ],
  },
  {
    path: "/UnauthorizedPage",
    name: "UnauthorizedPage",
    component: () => import("@/views/UnauthorizedPage"),
  },
  {
    path: "/asset",
    component: Main,
    name: "资产管理",
    children: [
      {
        path: "manifest/list",
        component: () => import("@/views/asset/manifest/list"),
        name: "资产清单",
      },
      {
        path: "usage/receiving/list",
        component: () => import("@/views/asset/usage/receiving/list"),
        name: "领用单",
      },
      {
        path: "usage/distribute/list",
        component: () => import("@/views/asset/usage/distribute/list"),
        name: "派发单",
      },
      {
        path: "usage/allocate/list",
        component: () => import("@/views/asset/usage/allocate/list"),
        name: "调拨单",
      },
      {
        path: "usage/return/list",
        component: () => import("@/views/asset/usage/return/list"),
        name: "退库单",
      },
      {
        path: "usage/borrow/list",
        component: () => import("@/views/asset/usage/borrow/list"),
        name: "借用单",
      },
      {
        path: "usage/giveBack/list",
        component: () => import("@/views/asset/usage/giveBack/list"),
        name: "归还单",
      },
      {
        path: "usage/repair/list",
        component: () => import("@/views/asset/usage/repair/list"),
        name: "维修单",
      },
      {
        path: "usage/maintain/list",
        component: () => import("@/views/asset/usage/maintain/list"),
        name: "保养单",
      },
      {
        path: "usage/dispose/list",
        component: () => import("@/views/asset/usage/dispose/list"),
        name: "处置单",
      },
      {
        path: "usage/inventory/list",
        component: () => import("@/views/asset/usage/inventory/list"),
        name: "盘点单",
      },
      {
        path: "setting/classify/list",
        component: () => import("@/views/asset/setting/classify/list"),
        name: "资产分类",
      },
      {
        path: "setting/location/list",
        component: () => import("@/views/asset/setting/location/list"),
        name: "资产位置",
      },
      {
        path: "setting/company/list",
        component: () => import("@/views/asset/setting/company/list"),
        name: "所属公司",
      },
      {
        path: "setting/label/list",
        component: () => import("@/views/asset/setting/label/list"),
        name: "标签模板",
      },
      {
        path: "record/list",
        component: () => import("@/views/asset/record/list"),
        name: "使用记录",
      },
    ],
  },
  {
    path: "/system",
    component: Main,
    name: "系统管理",
    children: [
      {
        path: "user/list",
        component: () => import("@/views/system/user/list"),
        name: "用户列表",
      },
      {
        path: "user/edit",
        component: () => import("@/views/system/user/edit"),
        name: "用户编辑",
      },
      {
        path: "role/list",
        component: () => import("@/views/system/role/list"),
        name: "角色列表",
      },
      {
        path: "role/add",
        component: () => import("@/views/system/role/add"),
        name: "角色添加",
      },
      {
        path: "role/edit",
        component: () => import("@/views/system/role/edit"),
        name: "角色编辑",
      },
      {
        path: "loginLog/list",
        component: () => import("@/views/system/loginLog/list"),
        name: "登录日志列表",
      },
      {
        path: "operationLog/list",
        component: () => import("@/views/system/operationLog/list"),
        name: "操作日志列表",
      },
      {
        path: "permissions/list",
        component: () => import("@/views/system/permissions/list"),
        name: "权限列表",
      },
      {
        path: "dictionary/list",
        component: () => import("@/views/system/dictionary/list"),
        name: "数据字典",
      },
    ],
  },
  {
    path: "/approval",
    component: Main,
    name: "审批设置",
    children: [
      {
        path: "list",
        component: () => import("@/views/approval/list"),
        name: "审批管理",
      },
    ],
  },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.path === '/' || to.path === '/login') {
        window.sessionStorage.removeItem("menuItem");
        window.sessionStorage.removeItem("submenu");
    }

    if (to.path.startsWith('/login') || to.path.startsWith('/authority')) {
        window.sessionStorage.removeItem('Authorization');
        next();
    } else if (to.path.startsWith('/UnauthorizedPage')) {
        if (to.query.t) {
            window.sessionStorage.setItem('un', to.query.un);
            window.sessionStorage.setItem('Authorization', to.query.t);
        }
        next();
    } else {
        let Authorization = window.sessionStorage.getItem('Authorization');
        if (Authorization) {
            next();
        } else {
            next({path: '/login'});
        }
    }
});

export default router

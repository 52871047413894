<template>
    <div>
        <div v-for="(menuItem,menuIndex) in menuDatas" :key="menuIndex">
            <!-- 展开没有子菜单 -->
            <MenuItem v-if="!menuItem.children || menuItem.children.length==0" :name="menuItem.permissionId" :to="menuItem.permissionUrl+'?permissionId='+menuItem.permissionId">
                <Icon :type="menuItem.permissionIcon" />
                <span>{{ menuItem.permissionName }}</span>
            </MenuItem>
            <!-- 展开有子菜单 -->
            <Submenu v-else :name="menuItem.permissionId">
                <template slot="title">
                    <Icon :type="menuItem.permissionIcon" />
                    <span>{{menuItem.permissionName}}</span>
                </template>
                <NavMenu :menuDatas="menuItem.children"></NavMenu>
            </Submenu>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NavMenu',
    props: ['menuDatas'],
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {
    }
}
</script>
<template>
    <div class="main">
        <Layout>
            <Header></Header>
            <Layout class="body">
                <Sider class="sidebar" hide-trigger width="220">
                    <Menu theme="light" width="auto" accordion :active-name="menuItem" :open-names="submenu" @on-select="selectMenu" @on-open-change="openMenu">
                        <NavMenu :menuDatas="menuDatas"></NavMenu>
                    </Menu>
                </Sider>
                <Content class="content">
                    <Card><router-view :key="key"></router-view></Card>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>
<script>
import {reqGetLeftMenus} from '../api/system-api'
import NavMenu from '../components/menu/NavMenu.vue'
import Header from '../components/Header/Header.vue'
export default {
    components: {
        NavMenu: NavMenu,
        Header: Header
    },
    data() {
        return {
            menuDatas: [],
            menuItem:'',
            submenu: []
        }
    },
    methods: {
        selectMenu(name){
            this.menuItem = name;
            sessionStorage.setItem('menuItem', name);
        },
        openMenu(name){
            this.submenu = name;
            sessionStorage.setItem('submenu', name);
        },
        //查询左侧树
        getLeftMenus(){
            reqGetLeftMenus({}).then((res) => {
                this.menuDatas = res.data.data;
            });
        },
    },
    computed: {
        key() {
            return this.$route.path;
        }
    },
    mounted() {
        this.getLeftMenus();
        this.submenu = sessionStorage.getItem("submenu") ? [parseInt(sessionStorage.getItem("submenu"))] : [];
        this.menuItem = sessionStorage.getItem("menuItem") || '';
    }
}
</script>
